import { IHttpClient } from '../@interfaces/IHttpClient';

export class DashboardService {
  constructor(private readonly httpClient: IHttpClient) {}

  async list(fromDate: string, toDate: string, userID?: string) {
    return this.httpClient.post('/report/dashboard', {
      body: {
        filter: {
          fromDate,
          toDate,
          userID,
        },
      },
    });
  }

  async exportExcel(fromDate: string, toDate: string) {
    const response = await this.httpClient.post('/report/dashboard/excel', {
      body: {
        filter: {
          fromDate,
          toDate,
        },
      },
      responseType: 'blob',
    });

    if (response.statusCode !== 200) {
      return response;
    }

    return {
      ...response,
      body: new Blob([response.body]),
    };
  }
}
