import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

import { useAuth } from '@app/hooks/contexts/useAuth';
import { useGlobalCache } from '@app/hooks/contexts/useGlobalCache';
import { cn } from '@app/utils/cn';

export function UserMenu() {
  const { idmUser, logout, changePassword, goToProfile, is } = useAuth();
  const { tenantConfig } = useGlobalCache();

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>

        <div className="grid h-10 w-10 place-items-center rounded-full bg-gray-200">
          <span className="font-bold uppercase text-gray-800">
            {idmUser?.firstName.charAt(0)}
          </span>
        </div>

        <div className="hidden lg:flex lg:items-center">
          <div className="ml-4 flex flex-col text-left">
            <span
              className="text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              {idmUser?.firstName} {idmUser?.lastName}
            </span>

            {is('SYSADMIN') && (
              <div className="-mt-1 flex items-center">
                <div className="mr-2 h-2 w-2 rounded-full bg-primary-main" />
                <small className="text-center text-xs font-medium text-primary-main">
                  SysAdmin
                </small>
              </div>
            )}
          </div>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() =>
                  goToProfile(tenantConfig?.customer.portalConfig.domain ?? '')
                }
                className={cn(
                  'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900',
                  active && 'bg-gray-50',
                )}
              >
                Profile
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={changePassword}
                className={cn(
                  'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900',
                  active && 'bg-gray-50',
                )}
              >
                Change password
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={logout}
                className={cn(
                  'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900',
                  active && 'bg-gray-50',
                )}
              >
                Sign out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
