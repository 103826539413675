import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuth } from '@app/hooks/contexts/useAuth';
import { useServices } from '@app/hooks/contexts/useServices';

export function useUserManagement(
  { enabled }: { enabled?: boolean } = { enabled: true },
) {
  const { userManagementService } = useServices();
  const { is } = useAuth();

  const { data, isError, isFetching, isSuccess } = useQuery({
    enabled: enabled && (is('ADMIN') || is('SYSADMIN')),
    queryKey: ['users'],
    queryFn: async () => {
      const resp = await userManagementService.list();

      if (resp.statusCode === 200) {
        return resp.body;
      }

      throw new Error('Error while loading users.');
    },
  });

  return useMemo(
    () => ({
      users: data ?? [],
      isError,
      isFetching,
      isSuccess,
    }),
    [data, isError, isFetching, isSuccess],
  );
}
