import { RouteObject } from 'react-router-dom';

import { RoleGuard } from '@/router/guards/RoleGuard';
import { routes } from '@app/config/constants/routes';
import { lazyLoad } from '@app/utils/lazyLoad';

const { ActivityByRSA } = lazyLoad(() => import('@ui/pages/ActivityByRSA'));
const { SubmissionHistory } = lazyLoad(
  () => import('@ui/pages/SubmissionHistory'),
);
const { ViewSubmissions } = lazyLoad(() => import('@ui/pages/ViewSubmissions'));
const { EditActivityReport } = lazyLoad(
  () => import('@ui/pages/EditActivityReport'),
);
const { NewActivityReport } = lazyLoad(
  () => import('@ui/pages/NewActivityReport'),
);

export const commonRoutes: RouteObject[] = [
  {
    element: <RoleGuard allowedRoles={['SYSADMIN', 'ADMIN', 'USER']} />,
    children: [
      {
        path: routes.activityByRSA,
        element: <ActivityByRSA />,
      },
      { path: routes.submissionHistory, element: <SubmissionHistory /> },
      { path: routes.viewSubmissions, element: <ViewSubmissions /> },
      { path: routes.newActivityReport, element: <NewActivityReport /> },
      {
        path: routes.newActivityReportDuplicate,
        element: <NewActivityReport />,
      },
      { path: routes.editActivityReport, element: <EditActivityReport /> },
    ],
  },
];
