import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { RouteKey, routes } from '@app/config/constants/routes';

export function useIsCurrentRoute() {
  const location = useLocation();

  const isCurrentRoute = useCallback(
    (routeKey: RouteKey) => {
      const pathname = routes[routeKey];

      if (pathname === '/') {
        return location.pathname === '/';
      }

      return location.pathname.startsWith(pathname);
    },
    [location],
  );

  return { isCurrentRoute };
}
