import { Navigate, Outlet } from 'react-router-dom';

import { RoleKey } from '@app/config/constants/roles';
import { useAuth } from '@app/hooks/contexts/useAuth';

interface IRoleGuardProps {
  allowedRoles: RoleKey | RoleKey[];
}

export function RoleGuard({ allowedRoles }: IRoleGuardProps) {
  const { is } = useAuth();

  const isSysadmin = is('SYSADMIN');

  if (!isSysadmin && !is(allowedRoles)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}
