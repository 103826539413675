import { useEffect, useRef } from 'react';

import { useAuth } from '@app/hooks/contexts/useAuth';
import { useCUs } from '@app/hooks/data/companies/useCUs';
import { useTenantConfig } from '@app/hooks/data/tenants/useTenantConfig';
import { useCreateUser } from '@app/hooks/data/users/useCreateUser';
import { useGetUser } from '@app/hooks/data/users/useGetUser';
import { useUserManagement } from '@app/hooks/data/users/useUserManagement';

interface IUseLoadInitialDataOptions {
  enabled: boolean;
  onSuccess: () => void;
  onError: () => void;
}

export function useLoadInitialData({
  enabled,
  onSuccess,
  onError,
}: IUseLoadInitialDataOptions) {
  const memoOnSuccess = useRef(onSuccess);
  const memoOnError = useRef(onError);
  const { idmUser, idm, is } = useAuth();
  const { createUser } = useCreateUser();

  const tenantConfig = useTenantConfig({ enabled });

  const enableUsersRequest = is('SYSADMIN') || is('ADMIN');
  const users = useUserManagement({
    enabled: enableUsersRequest,
  });

  const enableUserRequest = !is('SYSADMIN') && !is('ADMIN');
  const user = useGetUser(idmUser!.id, {
    enabled: enableUserRequest,
  });

  const { cus, isSuccess: cusRequestSucceeded } = useCUs();

  useEffect(() => {
    async function run() {
      if (idmUser && !user.user && user.isError) {
        const body = {
          id: idmUser.id,
          email: idmUser.email,
          firstName: idmUser.firstName,
          lastName: idmUser.lastName,
          displayname: `${idmUser.firstName} ${idmUser.lastName}`,
        };
        try {
          await createUser(body);
          await user.refetchUser();
          memoOnSuccess.current();
        } catch {
          memoOnError.current();
        }
      }
    }

    run();
  }, [idmUser, user, idm, createUser]);

  useEffect(() => {
    const usersRequestSucceeded = !enableUsersRequest ? true : users.isSuccess;
    const userRequestSucceeded = !enableUserRequest ? true : user.isSuccess;

    if (
      tenantConfig.isSuccess &&
      usersRequestSucceeded &&
      userRequestSucceeded &&
      cusRequestSucceeded
    ) {
      memoOnSuccess.current();
    }

    if (tenantConfig.isError) {
      memoOnError.current();
    }
  }, [
    enableUserRequest,
    enableUsersRequest,
    tenantConfig.isError,
    tenantConfig.isSuccess,
    user.isError,
    user.isSuccess,
    users.isError,
    users.isSuccess,
    cusRequestSucceeded,
  ]);

  return {
    tenantConfig: tenantConfig.data,
    users,
    user: user.user,
    cus,
  };
}
