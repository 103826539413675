/* eslint-disable no-underscore-dangle */
import IDM, { type IDMOptions } from '@ikonintegration/idmclient';

export class IDMService {
  private readonly idmClient: IDM;

  private readonly tenantID: string;

  private readonly roles: string[];

  constructor({
    accessKey,
    accessSecret,
    idmOptions,
    tenantID,
    roles,
  }: {
    accessKey: string;
    accessSecret: string;
    idmOptions: IDMOptions;
    tenantID: string;
    roles: string[];
  }) {
    this.idmClient = new IDM(accessKey, accessSecret, idmOptions);
    this.tenantID = tenantID;
    this.roles = roles;
  }

  async validateSession() {
    if (!this.idmClient.isLogged()) {
      await this.idmClient.load(null, { tenantID: this.tenantID });
    }
    if (!this.idmClient.isLogged()) {
      await this.redirectToAuth();
      return false;
    }
    return true;
  }

  async redirectToAuth() {
    await this.idmClient.authenticator.login(
      null,
      null,
      this.roles,
      window.location,
      { tenantID: this.tenantID },
    );
  }

  async getFullAccessToken() {
    if (this.idmClient.session.authorization) {
      const token = await this.idmClient.session.getToken(true);

      return `Bearer ${token}`;
    }

    return null;
  }

  clearSession() {
    return this.idmClient.session.clearSession();
  }

  getUserObject() {
    return this.idmClient.session.data.getUserObject();
  }

  logout() {
    return this.idmClient.authenticator.logout();
  }

  changePassword() {
    return this.idmClient.user.changePassword({ tenantID: this.tenantID });
  }

  getProfileURL(portalDomain: string) {
    return this.idmClient.urlmanager.getRedirectURL(
      portalDomain,
      '/user',
      null,
      null,
      null,
      this.tenantID ? { tenantID: this.tenantID } : null,
      true,
    );
  }

  hasClaim(claim: string) {
    return this.idmClient.session.authorization?.hasClaim(claim);
  }

  getTokenExpiration() {
    return this.idmClient.session?.authorization?._decoded?.payload.exp;
  }
}
