import { ISystemSettings } from '@app/entities/ISystemSettings';

export class SystemSettingsMapper {
  static toDomain(data: any): ISystemSettings {
    return {
      longtravelkm: data.longtravelkm,
      valuekmdriven: data.valuekmdriven,
      annualrsamaxexpenses: data.annualrsamaxexpenses,
      gstrate: data.gstrate,
      emailpayments: data.emailpayments,
      adminemail: data.adminemail,
      timepaidshortdistance: data.timepaidshortdistance,
      timepaidlongdistance: data.timepaidlongdistance,
    };
  }
}
