import { createContext, useCallback, useMemo } from 'react';

import { tenantsConfig } from '@app/config/tenants';

import { ITenantConfig } from './@interfaces/ITenantConfig';

type ITenantContextValue = ITenantConfig;

export const TenantContext = createContext({} as ITenantContextValue);

export function TenantProvider({ children }: { children: React.ReactNode }) {
  const getTenantConfig = useCallback(() => {
    const { hostname } = new URL(window.location.href);

    for (const [tenantDomain, tenantConfig] of Object.entries(tenantsConfig)) {
      const domainWithoutWildcard = tenantDomain.replace('*.', '');

      if (hostname.includes(domainWithoutWildcard)) {
        return tenantConfig;
      }
    }

    throw new Error('Unknown Tenant.');
  }, []);

  const value = useMemo<ITenantContextValue>(
    () => getTenantConfig(),
    [getTenantConfig],
  );

  return (
    <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
  );
}
