import NProgress from 'nprogress';
import { useEffect } from 'react';

import './styles.css';

export function PageLoader() {
  useEffect(() => {
    NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });

    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return null;
}
