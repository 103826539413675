import { ITenantConfig } from '@app/entities/ITenantConfig';

import { IHttpClient } from '../@interfaces/IHttpClient';
import { TenantConfigMapper } from '../mappers/TenantConfigMapper';

export class TenantsService {
  constructor(private readonly httpClient: IHttpClient) {}

  async getConfiguration(): Promise<IHttpClient.Response<ITenantConfig>> {
    const response = await this.httpClient.get('/tenant/config');

    return {
      statusCode: 200,
      body: TenantConfigMapper.toDomain(response),
    };
  }
}
