import { Link, LinkProps } from 'react-router-dom';

import { RouteKey } from '@app/config/constants/routes';
import { useUrlManager } from '@app/hooks/app/useNavigate';

interface IRouteLinkProps extends LinkProps {
  to: RouteKey;
  children: React.ReactNode;
  params?: Record<string, string>;
}

export function RouteLink({ children, to, params, ...props }: IRouteLinkProps) {
  const { getParsedURL } = useUrlManager();

  const route = getParsedURL(to, params);

  return (
    <Link to={route} {...props}>
      {children}
    </Link>
  );
}
