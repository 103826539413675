import {
  // ClipboardDocumentIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  DocumentChartBarIcon,
  ChartBarSquareIcon,
} from '@heroicons/react/24/outline';

import { roles } from '@app/config/constants/roles';
import { RouteKey } from '@app/config/constants/routes';

interface ISidebarOption {
  name: string;
  routeKey: RouteKey;
  icon: React.ComponentType<any>;
  allowedRoles?: (keyof typeof roles)[];
  deniedRoles?: (keyof typeof roles)[];
}

export const sidebarOptions: ISidebarOption[] = [
  {
    name: 'Dashboard',
    routeKey: 'dashboard',
    icon: ChartBarSquareIcon,
    allowedRoles: ['ADMIN', 'SYSADMIN'],
  },
  {
    name: 'Pending Submissions',
    routeKey: 'pendingSubmissions',
    icon: ClockIcon,
    allowedRoles: ['ADMIN', 'SYSADMIN'],
  },
  {
    name: 'Activity Report',
    routeKey: 'activityReport',
    icon: DocumentChartBarIcon,
    allowedRoles: ['USER'],
    deniedRoles: ['ADMIN', 'SYSADMIN'],
  },
  {
    name: 'Submission History',
    routeKey: 'submissionHistory',
    icon: DocumentTextIcon,
    allowedRoles: ['USER', 'ADMIN', 'SYSADMIN'],
  },
  {
    name: 'Activity by RSA',
    routeKey: 'activityByRSA',
    icon: DocumentDuplicateIcon,
    allowedRoles: ['USER', 'ADMIN', 'SYSADMIN'],
  },
  // {
  //   name: 'Reports',
  //   routeKey: 'reports',
  //   icon: ClipboardDocumentIcon,
  //   allowedRoles: ['ADMIN', 'SYSADMIN'],
  // },
];
