import { Sidebar } from './Sidebar';

export function DesktopSidebar() {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-300 bg-gradient-to-br from-gray-100 to-gray-300 px-6 pb-4">
        <Sidebar />
      </div>
    </div>
  );
}
