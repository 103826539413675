import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useServices } from '@app/hooks/contexts/useServices';

export function useCUs() {
  const { orgModule } = useServices();

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ['classificationUnits'],
    queryFn: async () => {
      const resp =
        await orgModule.classificationUnit.getAllClassificationUnits();

      if (resp.statusCode === 200) {
        return resp.body.classificationUnits;
      }

      throw new Error('Error while loading classification units.');
    },
  });

  return useMemo(
    () => ({
      cus: data ?? [],
      isLoading: isFetching,
      isSuccess,
    }),
    [data, isFetching, isSuccess],
  );
}
