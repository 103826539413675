export const baseTheme = {
  colors: {
    primary: {
      main: '#ad3846',
      50: '#ffe8eb',
      100: '#f1c3c9',
      200: '#e29ea7',
      300: '#d47783',
      400: '#c75260',
      500: '#ad3846',
      600: '#882b37',
      700: '#621d26',
      800: '#3d1016',
      900: '#1c0106',
    },
  },
};
