import { Theme } from '@ui/themes/@interfaces/Theme';

type MappedTheme = Record<string, string>;

export function makeMappedTheme(theme: Theme): MappedTheme {
  const colors = Object.entries(theme.colors).reduce(
    (colorsAcc, [colorName, variants]) => {
      const cssVarPrefix = `--color-${colorName}`;

      const colorVariants = Object.entries(variants).reduce(
        (variantsAcc, [colorKey, colorCode]) => ({
          ...variantsAcc,
          [`${cssVarPrefix}-${colorKey}`]: colorCode,
        }),
        {},
      );

      return {
        ...colorsAcc,
        ...colorVariants,
      };
    },
    {},
  );

  return {
    ...colors,
  };
}
