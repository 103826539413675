import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { RootProvider } from '@app/contexts/RootProvider';
import { ErrorBoundary } from '@ui/components/ui/ErrorBoundary';

import { Router } from './router';

function App() {
  return (
    <ErrorBoundary>
      <RootProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </RootProvider>

      <Toaster />
    </ErrorBoundary>
  );
}

export default App;
