import { useCallback, useMemo } from 'react';

import { type RoleKey, roles as appRoles } from '@app/config/constants/roles';
import { IDMService } from '@app/data/http/services/IDMService';

export type IsFn = (
  roles: RoleKey | RoleKey[],
  options?: { shouldHasAll: boolean },
) => boolean;

export function useRoleChecker(idm: IDMService) {
  const isSysAdmin = useCallback(
    () => idm.hasClaim(appRoles.SYSADMIN) ?? false,
    [idm],
  );

  const isAdmin = useCallback(
    () => idm.hasClaim(appRoles.ADMIN) ?? false,
    [idm],
  );

  const isUser = useCallback(() => idm.hasClaim(appRoles.USER) ?? false, [idm]);

  const roleCheckersMap = useMemo<Record<RoleKey, () => boolean>>(
    () => ({
      SYSADMIN: isSysAdmin,
      ADMIN: isAdmin,
      USER: isUser,
    }),
    [isAdmin, isSysAdmin, isUser],
  );

  const is = useCallback<IsFn>(
    (roles, options = { shouldHasAll: false }) => {
      const { shouldHasAll } = options;
      const allowedRoles = !Array.isArray(roles) ? [roles] : roles;

      const checkRoles = allowedRoles.map(
        role => roleCheckersMap[role]?.() ?? false,
      );

      if (shouldHasAll) {
        return checkRoles.every(check => check === true);
      }

      return checkRoles.includes(true);
    },
    [roleCheckersMap],
  );

  return { is };
}
