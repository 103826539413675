import { IActivity } from '@app/entities/IActivity';

export class ActivityMapper {
  static toDomain(data: any): IActivity {
    return {
      activityDate: data.activityDate,
      id: data.id,
      userID: data.userID,
      description: data.description,
      companyname: data.companyname,
      corProgramSize: data.corProgramSize,
      contactTypeV2: data.contactTypeV2,
      consulting: {
        consultingpayment: data.consulting.consultingpayment,
        consultinggst: data.consulting.consultinggst,
        consultingaftertax: data.consulting.consultingaftertax,
        consultinghours: data.consulting.consultinghours,
      },
      meal: {
        mealamount: data.meal.mealamount,
        mealgst: data.meal.mealgst,
        mealtotal: data.meal.mealtotal,
      },
      travel: {
        kmgst: data.travel.kmgst,
        kmreimbursement: data.travel.kmreimbursement,
        km: data.travel.km,
        kmtotal: data.travel.kmtotal,
        travelreimbursement: data.travel.travelreimbursement,
        traveltime: data.travel.traveltime,
        traveltimegst: data.travel.traveltimegst,
        traveltimetotal: data.travel.traveltimetotal,
      },
      othercosts: data.othercosts,
      totalclaimaftertax: data.totalclaimaftertax,
      totalclaimgst: data.totalclaimgst,
      totalclaim: data.totalclaim ? Number(data.totalclaim) : 0,
      contactID: data.contactID,
      sizePersonYears: data.sizePersonYears,
    };
  }

  static calculateSubmission(activities: IActivity[], gstrate?: number) {
    const initialSubtotals = {
      totalConsultingHours: 0,
      totalConsultingPayment: 0,
      totalConsultinggst: 0,
      totalConsultingCost: 0,
      totalTravelHours: 0,
      totalTravelCost: 0,
      totalTravelTimegst: 0,
      totalTravelTotal: 0,
      totalKM: 0,
      totalKMCost: 0,
      totalKmgst: 0,
      totalKMTotal: 0,
      totalOtherCosts: 0,
      totalClaim: 0,
      totalClaimgst: 0,
      totalClaimAfterTax: 0,
      mealamount: 0,
      mealgst: 0,
      mealtotal: 0,
    };

    const submissionTotal = activities.reduce((acc, item) => {
      console.log('item meal', item);
      acc.totalConsultingHours += +item.consulting.consultinghours || 0;
      acc.totalConsultingPayment += +item.consulting.consultingpayment || 0;
      acc.totalTravelHours += +item.travel.traveltime || 0;
      acc.totalTravelCost += +item.travel.travelreimbursement || 0;
      acc.totalKM += +item.travel.km || 0;
      acc.totalKMCost += +item.travel.kmreimbursement || 0;
      acc.mealamount += +item.meal.mealamount || 0;
      acc.mealgst += +item.meal.mealgst || 0;
      acc.mealtotal += +item.meal.mealtotal || 0;
      acc.totalOtherCosts += +item.othercosts || 0;
      // acc.totalClaim += +item.totalclaim;
      return acc;
    }, initialSubtotals);
    submissionTotal.totalConsultingHours =
      +submissionTotal.totalConsultingHours.toFixed(2);

    // calculates gst
    const gstrateDecimal = (gstrate ?? 0) * 0.01;
    submissionTotal.totalConsultinggst = +(
      submissionTotal.totalConsultingPayment * gstrateDecimal
    ).toFixed(2);
    submissionTotal.totalKmgst = +(
      submissionTotal.totalKMCost * gstrateDecimal
    ).toFixed(2);
    submissionTotal.totalTravelTimegst = +(
      submissionTotal.totalTravelCost * gstrateDecimal
    ).toFixed(2);

    // calculates grand totals
    submissionTotal.totalConsultingCost = +(
      submissionTotal.totalConsultingPayment +
      submissionTotal.totalConsultinggst
    ).toFixed(2);
    submissionTotal.totalKMTotal = +(
      submissionTotal.totalKMCost + submissionTotal.totalKmgst
    ).toFixed(2);
    submissionTotal.totalTravelTotal = +(
      submissionTotal.totalTravelCost + submissionTotal.totalTravelTimegst
    ).toFixed(2);
    // payment before tax

    submissionTotal.totalClaim = +(
      submissionTotal.totalConsultingPayment +
      submissionTotal.totalKMCost +
      submissionTotal.totalTravelCost +
      submissionTotal.mealamount +
      submissionTotal.totalOtherCosts
    ).toFixed(2);
    submissionTotal.totalClaimgst = +(
      submissionTotal.totalConsultinggst +
      submissionTotal.totalKmgst +
      submissionTotal.totalTravelTimegst +
      submissionTotal.mealgst
    ).toFixed(2);
    submissionTotal.totalClaimAfterTax = +(
      submissionTotal.totalClaim + submissionTotal.totalClaimgst
    ).toFixed(2);

    // calculates the final values for the form
    console.log('*-*- submission', submissionTotal);
    return submissionTotal;
  }

  static calculateSubTotals(activities: IActivity[]) {
    const initialSubtotals = {
      totalConsultingHours: 0,
      totalConsultingPayment: 0,
      totalConsultingCost: 0,
      totalTravelHours: 0,
      totalTravelCost: 0,
      totalKM: 0,
      totalKMCost: 0,
      totalOtherCosts: 0,
      totalClaim: 0,
      totalClaimAfterTax: 0,
    };

    const subtotal = activities.reduce((acc, item) => {
      acc.totalConsultingHours += +item.consulting.consultinghours;
      acc.totalConsultingPayment += +item.consulting.consultingpayment;
      acc.totalConsultingCost += +item.consulting.consultingaftertax;
      acc.totalTravelHours += +item.travel.traveltime || 0;
      acc.totalTravelCost += +item.travel.travelreimbursement;
      acc.totalKM += +item.travel.km || 0;
      acc.totalKMCost += +item.travel.kmreimbursement;
      acc.totalOtherCosts += +item.othercosts;
      acc.totalClaim += +item.totalclaim;
      acc.totalClaimAfterTax += +item.totalclaimaftertax || 0;
      return acc;
    }, initialSubtotals);

    return subtotal;
  }

  static calculateGST(activities: IActivity[], gstrate?: number) {
    const subtotals = this.calculateSubTotals(activities);
    const gstrateDecimal = (gstrate ?? 0) * 0.01;

    const gst = {
      totalConsultinggst: +(
        subtotals.totalConsultingPayment * gstrateDecimal
      ).toFixed(2),
      totalTravelTimegst: +(subtotals.totalTravelCost * gstrateDecimal).toFixed(
        2,
      ),
      totalKmgst: +(subtotals.totalKMCost * gstrateDecimal).toFixed(2),
      totalClaimgst: activities.reduce(
        (acc, item) => acc + +item.totalclaimgst,
        0,
      ),
    };

    return gst;
  }

  static calculateGSTFromTotals(
    totals: ReturnType<typeof ActivityMapper.calculateSubmission>,
  ) {
    const gst = {
      totalConsultinggst: totals.totalConsultinggst,
      totalTravelTimegst: totals.totalTravelTimegst,
      totalKmgst: totals.totalKmgst,
      totalClaimgst: totals.totalClaimgst,
    };
    return gst;
  }

  static calculateMealTotal(activities: IActivity[]) {
    const initialMeal = {
      mealamount: 0,
      mealgst: 0,
      mealtotal: 0,
    };

    const meal = activities.reduce((acc, item) => {
      acc.mealamount += +item.meal.mealamount;
      acc.mealgst += +item.meal.mealgst;
      acc.mealtotal += +item.meal.mealtotal;
      return acc;
    }, initialMeal);

    return meal;
  }

  // deprecated use calculateSubmission
  static calculateTotal(activities: IActivity[]) {
    const initialTotals = {
      totalDescriptions: 0,
      totalConsultingHours: 0,
      totalConsultingAfterTax: 0,
      totalTravelHours: 0,
      totalTravelCost: 0,
      totalTravelTotal: 0,
      totalKM: 0,
      totalKMCost: 0,
      totalOtherCosts: 0,
      totalClaimAfterTax: 0,
      totalKMTotal: 0,
    };

    const total = activities.reduce((acc, item) => {
      acc.totalDescriptions += 1;
      acc.totalConsultingHours += +item.consulting.consultinghours;
      acc.totalConsultingAfterTax +=
        +item.consulting.consultingpayment + +item.consulting.consultinggst;
      acc.totalTravelHours += +item.travel.traveltime || 0;
      acc.totalTravelCost +=
        +item.travel.travelreimbursement + +item.travel.traveltimegst;
      acc.totalKM += +item.travel.km || 0;
      acc.totalKMCost += +item.travel.kmreimbursement + +item.travel.kmgst;
      acc.totalOtherCosts += +item.othercosts;
      acc.totalClaimAfterTax += +item.totalclaimaftertax;
      acc.totalKMTotal += +item.travel.kmtotal;
      acc.totalTravelTotal += +item.travel.traveltimetotal;
      return acc;
    }, initialTotals);

    return total;
  }

  static calculateTotalFromTotals(
    activities: IActivity[],
    totals: ReturnType<typeof ActivityMapper.calculateSubmission>,
  ) {
    const total = {
      totalDescriptions: activities.length || 0,
      totalConsultingHours: totals.totalConsultingHours,
      totalConsultingAfterTax: totals.totalConsultingCost,
      totalTravelHours: totals.totalTravelHours,
      totalTravelTotal: totals.totalTravelTotal,
      totalTravelCost: totals.totalTravelCost,
      totalKM: totals.totalKM,
      totalKMCost: totals.totalKMCost,
      totalOtherCosts: totals.totalOtherCosts,
      totalClaimAfterTax: totals.totalClaimAfterTax,
      totalKMTotal: totals.totalKMTotal,
    };
    return total;
  }
}
