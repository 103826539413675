import { useMutation } from '@tanstack/react-query';

import { ICreateUserRequestDTO } from '@app/data/http/services/dtos/UserServiceDTO';
import { useServices } from '@app/hooks/contexts/useServices';

export function useCreateUser() {
  const { userManagementService } = useServices();

  const { isLoading, mutateAsync } = useMutation(
    async (body: ICreateUserRequestDTO) => {
      const response = await userManagementService.create(body);

      if (response.statusCode !== 200) {
        throw new Error('Error while creating user.');
      }

      return response;
    },
    { retry: 2 },
  );

  return {
    isLoading,
    createUser: mutateAsync,
  };
}
