import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@app/hooks/contexts/useAuth';

interface IAuthGuardProps {
  isPrivate: boolean;
}

export function AuthGuard({ isPrivate }: IAuthGuardProps) {
  const { signedIn, forceValidate } = useAuth();
  forceValidate();

  if (isPrivate && !signedIn) {
    return <Navigate to="/signin" replace />;
  }

  if (!isPrivate && signedIn) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}
