import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useServices } from '@app/hooks/contexts/useServices';

export function useGetUser(
  userId: string,
  { enabled }: { enabled?: boolean } = { enabled: true },
) {
  const { userManagementService } = useServices();

  const { data, isError, isFetching, isSuccess, refetch } = useQuery({
    enabled,
    queryKey: ['user', userId],
    queryFn: async () => {
      const resp = await userManagementService.getUser(userId);

      if (resp.statusCode === 200) {
        return resp.body;
      }

      throw new Error('Error while loading user information.');
    },
  });

  return useMemo(
    () => ({
      user: data ?? null,
      isError,
      isFetching,
      isSuccess,
      refetchUser: refetch,
    }),
    [data, isError, isFetching, isSuccess, refetch],
  );
}
