import { IUser } from '@app/entities/IUser';

import { IHttpClient } from '../@interfaces/IHttpClient';
import { UserMapper } from '../mappers/UserMapper';

import { ICreateUserRequestDTO } from './dtos/UserServiceDTO';

export class UserManagementService {
  constructor(private readonly httpClient: IHttpClient) {}

  create(body: ICreateUserRequestDTO) {
    return this.httpClient.post(`/user/${body.id}`, {
      body,
    });
  }

  async list() {
    const { body, statusCode } = await this.httpClient.get('/users');

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    const users = body.users.map(UserMapper.toDomain);

    return {
      statusCode,
      body: users,
    };
  }

  async getUser(userId: string) {
    const { body, statusCode } = await this.httpClient.get(`/user/${userId}`);

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    const user = UserMapper.toDomain(body);

    return {
      statusCode,
      body: user,
    };
  }

  update(userId: string, user: IUser) {
    return this.httpClient.put(`/user/${userId}`, {
      body: user,
    });
  }
}
