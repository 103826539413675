import { Navigate, RouteObject } from 'react-router-dom';

import { RoleGuard } from '@/router/guards/RoleGuard';
import { routes } from '@app/config/constants/routes';
import { lazyLoad } from '@app/utils/lazyLoad';

const { ActivityReport } = lazyLoad(() => import('@ui/pages/ActivityReport'));

export const userRoutes: RouteObject[] = [
  {
    element: <RoleGuard allowedRoles="USER" />,
    children: [
      { path: '/', element: <Navigate to={routes.activityReport} replace /> },
      { path: routes.activityReport, element: <ActivityReport /> },
    ],
  },
];
