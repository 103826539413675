import { Component, ReactNode, ErrorInfo } from 'react';

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
  errorMessage: string | null;
}

export class ErrorBoundary extends Component<IProps, IState> {
  // eslint-disable-next-line react/state-in-constructor
  state = { hasError: false, errorMessage: null };

  isDev = import.meta.env.MODE === 'development';

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, info);

    if (this.isDev) {
      this.setState({ errorMessage: error.message });
    }
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <h1 className="text-4xl font-bold">Something went wrong!</h1>
          <p className="mt-2">
            An unexpected error occurred,{' '}
            <strong>contact the system administrator.</strong>
          </p>

          {this.isDev && (
            <div className="mt-6 text-center text-red-500">
              Message: <strong>{errorMessage}</strong>
            </div>
          )}
        </div>
      );
    }

    return children;
  }
}
