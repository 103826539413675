import { Suspense, useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { useAuth } from '@app/hooks/contexts/useAuth';
import { PageLoader } from '@ui/components/ui/PageLoader';
import { DashboardLayout } from '@ui/layouts/DashboardLayout';

import { AuthGuard } from './guards/AuthGuard';
import { adminRoutes } from './routes/admin';
import { commonRoutes } from './routes/common';
import { userRoutes } from './routes/user';

export function Router() {
  const { is } = useAuth();

  const routesByRole = useMemo(() => {
    let routes: RouteObject[] = [];

    if (is(['ADMIN', 'SYSADMIN'])) {
      routes = adminRoutes;
    }

    if (is('USER') && !is(['ADMIN', 'SYSADMIN'])) {
      routes = userRoutes;
    }

    return routes;
  }, [is]);

  const routes = useRoutes([
    {
      element: <AuthGuard isPrivate />,
      children: [
        {
          element: <DashboardLayout />,
          children: [...commonRoutes, ...routesByRole],
        },
      ],
    },

    // 404
    { path: '*', element: <Navigate to="/" replace /> },
  ]);

  return <Suspense fallback={<PageLoader />}>{routes}</Suspense>;
}
