import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteKey, routes } from '@app/config/constants/routes';

type NavigateOptions = {
  replace?: boolean;
  params?: Record<string, string>;
};

export function useUrlManager() {
  const reactRouterNavigate = useNavigate();

  const getParsedURL = useCallback(
    (routeKey: RouteKey, params: Record<string, any> = {}) => {
      let route = routes[routeKey];

      Object.entries(params).forEach(([key, value]) => {
        route = route.replace(`:${key}`, value);
      });

      return route;
    },
    [],
  );

  const navigate = useCallback(
    (routeKey: RouteKey, { params, ...options }: NavigateOptions = {}) => {
      const route = getParsedURL(routeKey, params);

      reactRouterNavigate(route, options);
    },
    [reactRouterNavigate, getParsedURL],
  );

  return { navigate, getParsedURL };
}
