import { Cog6ToothIcon } from '@heroicons/react/20/solid';

import { useIsCurrentRoute } from '@app/hooks/app/useIsCurrentRoute';
import { useAuth } from '@app/hooks/contexts/useAuth';
import { cn } from '@app/utils/cn';
import logo from '@ui/assets/images/logo-bccsa.png';
import { RouteLink } from '@ui/components/app/RouteLink';
import { sidebarOptions } from '@ui/config/sidebarOptions';

export function Sidebar() {
  const { is } = useAuth();
  const { isCurrentRoute } = useIsCurrentRoute();

  return (
    <>
      <div className="mt-2 flex h-16 shrink-0 items-center">
        <img className="h-11" src={logo} alt="BCCSA" />
      </div>

      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {sidebarOptions.map(option => {
                const isAllowed =
                  (!option.allowedRoles || is(option.allowedRoles)) &&
                  (!option.deniedRoles || !is(option.deniedRoles));

                if (!isAllowed) {
                  return null;
                }

                return (
                  <li key={Math.random()}>
                    <RouteLink
                      to={option.routeKey}
                      className={cn(
                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-600/80 transition-all',
                        'hover:bg-white hover:text-primary-main',
                        isCurrentRoute(option.routeKey) &&
                          'bg-white text-primary-main',
                      )}
                    >
                      <option.icon
                        className={cn(
                          'h-6 w-6 shrink-0 text-gray-600 transition-all group-hover:text-primary-main',
                          isCurrentRoute(option.routeKey) &&
                            'text-primary-main',
                        )}
                        aria-hidden="true"
                      />
                      {option.name}
                    </RouteLink>
                  </li>
                );
              })}
            </ul>
          </li>

          {is(['ADMIN', 'SYSADMIN']) && (
            <li className="mt-auto">
              <RouteLink
                to="settings"
                className={cn(
                  'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 transition-all',
                  'hover:bg-gray-50 hover:text-primary-main',
                  isCurrentRoute('settings') && 'bg-gray-50 text-primary-main',
                )}
              >
                <Cog6ToothIcon
                  className={cn(
                    'h-6 w-6 shrink-0 text-gray-400 transition-all group-hover:text-primary-main',
                    isCurrentRoute('settings') &&
                      'bg-gray-50 text-primary-main',
                  )}
                  aria-hidden="true"
                />
                Settings
              </RouteLink>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}
