import { ICategory } from '@app/entities/ICategory';

export class CategoryMapper {
  static toDomain(data: any): ICategory {
    return {
      createdBy: data.createdBy,
      createdOn: new Date(data.createdOn),
      id: data.id,
      description: data.description,
      updatedBy: data.updatedBy,
      updatedOn: new Date(data.updatedOn),
    };
  }
}
