import type { IDMUser } from '@ikonintegration/idmclient';
import LogRocket from 'logrocket';
import { createContext, useCallback, useMemo, useRef, useState } from 'react';

import { IDMService } from '@app/data/http/services/IDMService';
import { useTenant } from '@app/hooks/contexts/useTenant';
import { openExternalURL } from '@app/utils/openEsternalURL';
import { FullScreenLoader } from '@ui/components/ui/FullScreenLoader';

import { useRoleChecker, type IsFn } from './useRoleChecker';

const RENEW_GAP = 9 * 60 * 1000; // 9 minutes
interface IAuthContextValue {
  signedIn: boolean;
  idm: IDMService;
  idmUser: IDMUser | null;
  idmToken: string | null;
  tokenExpiration: number | null;
  logout(): Promise<void>;
  changePassword(): Promise<void>;
  goToProfile(portalDomain: string): Promise<void>;
  is: IsFn;
  forceValidate: () => void;
}

export const AuthContext = createContext({} as IAuthContextValue);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [idmUser, setIdmUser] = useState<IDMUser | null>(null);
  const [idmToken, setIdmToken] = useState<string | null>(null);
  const [tokenExpiration, setTokenExpiration] = useState<number | null>(null);

  const {
    tenantID,
    idm: { accessKey, accessSecret, portalUserRole, ...idmOptions },
  } = useTenant();

  const idm = useRef(
    new IDMService({
      accessKey,
      accessSecret,
      idmOptions,
      tenantID,
      roles: [portalUserRole],
    }),
  );

  const { is } = useRoleChecker(idm.current);

  const forceValidate = useCallback(async () => {
    async function run() {
      const isLogged = await idm.current.validateSession();

      if (isLogged) {
        const [user, token] = await Promise.all([
          idm.current.getUserObject(),
          idm.current.getFullAccessToken(),
        ]);

        setIdmUser(user);
        setIdmToken(token);
        setSignedIn(true);
        setIsLoading(false);

        // Identify the user with LogRocket
        if (user) {
          LogRocket.identify(user.id, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            application: 'BCCSA RSA',
          });
        }
      }

      // Set token expiration
      setTokenExpiration(idm?.current?.getTokenExpiration());

      const renewIn = (tokenExpiration || 0) * 1000 - Date.now() - RENEW_GAP;
      console.log('renewIn', renewIn / 1000 / 60); // TODO: REMOVE ME
      if ((renewIn || 0) > 0) {
        console.log('not ready, refreshing timer');
        setTimeout(() => {
          console.log('revalidating...');
          forceValidate();
        }, renewIn || 0);
      }
    }
    run();
  }, [tokenExpiration]);

  forceValidate();

  const logout = useCallback(async () => {
    setIsLoading(true);

    await idm.current.logout();
    await idm.current.redirectToAuth();
  }, []);

  const changePassword = useCallback(() => idm.current.changePassword(), []);

  const goToProfile = useCallback(async (portalDomain: string) => {
    const redirectURL = await idm.current.getProfileURL(portalDomain);

    openExternalURL(redirectURL);
  }, []);

  const value = useMemo<IAuthContextValue>(
    () => ({
      signedIn,
      idmUser,
      idmToken,
      idm: idm.current,
      tokenExpiration,
      logout,
      changePassword,
      goToProfile,
      is,
      forceValidate,
    }),
    [
      signedIn,
      idmUser,
      idmToken,
      tokenExpiration,
      logout,
      changePassword,
      goToProfile,
      is,
      forceValidate,
    ],
  );

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
