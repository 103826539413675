import { ITenantConfig } from '@app/entities/ITenantConfig';

export class TenantConfigMapper {
  static toDomain(data: any): ITenantConfig {
    return {
      customer: {
        portalConfig: {
          domain: data?.customer?.portalConfig?.domain ?? null,
        },
      },
    };
  }
}
