import { ITenantConfig } from '@app/contexts/@interfaces/ITenantConfig';

import BaseIDMSettings from '../dynamic/BaseIDMSettings.json';
import TenantsMap from '../dynamic/TenantsMap.json';

export const tenantsConfig = Object.entries(TenantsMap).reduce<
  Record<string, ITenantConfig>
>(
  (acc, [tenantDomain, tenantSettings]) => ({
    ...acc,
    [tenantDomain]: {
      tenantID: tenantSettings.tenantID,
      orgModEndpoint: tenantSettings.orgModEndpoint,
      idm: {
        ...BaseIDMSettings,
        accessKey: tenantSettings.idm.key,
        accessSecret: tenantSettings.idm.secret,
        autoEnrollRole: [
          tenantSettings.idm.roles.user,
          tenantSettings.idm.roles.portalUser,
        ],
        userRole: tenantSettings.idm.roles.user,
        portalUserRole: tenantSettings.idm.roles.portalUser,
        cookiesEndpoint: tenantSettings.idm.externalCookiesDomain,
        externalAuthDomain: tenantSettings.idm.externalAuthDomain,
        roles: {
          ...BaseIDMSettings.roles,
          ADMIN: tenantSettings.idm.roles.admin,
          USER: tenantSettings.idm.roles.user,
        },
      },
    },
  }),
  {},
);

tenantsConfig['local.bccsa-services.ca'] =
  tenantsConfig['training-dev.bccsa-services.ca'];
