import OrgAPI from '@ikonintegration/mod-organization-client';
import { createContext, useMemo } from 'react';

import AppSettings from '@app/config/dynamic/AppSettings.json';
import { IHttpClient } from '@app/data/http/@interfaces/IHttpClient';
import { AxiosHttpClient } from '@app/data/http/clients/AxiosHttpClient';
import { ActivitiesService } from '@app/data/http/services/ActivitiesService';
import { CategoriesService } from '@app/data/http/services/CategoriesService';
import { DashboardService } from '@app/data/http/services/DashboardService';
import { SubmissionsService } from '@app/data/http/services/SubmissionsService';
import { SystemSettingsService } from '@app/data/http/services/SystemSettingsService';
import { TenantsService } from '@app/data/http/services/TenantsService';
import { UserManagementService } from '@app/data/http/services/UserManagementService';
import { useAuth } from '@app/hooks/contexts/useAuth';
import { useTenant } from '@app/hooks/contexts/useTenant';

interface IServicesContextValue {
  tenantsService: TenantsService;
  systemSettingsService: SystemSettingsService;
  userManagementService: UserManagementService;
  categoriesService: CategoriesService;
  activitiesService: ActivitiesService;
  submissionsService: SubmissionsService;
  orgModule: OrgAPI;
  dashboardService: DashboardService;
}

export const ServicesContext = createContext({} as IServicesContextValue);

export function ServicesProvider({ children }: { children: React.ReactNode }) {
  const { tenantID, orgModEndpoint } = useTenant();
  const { idmToken } = useAuth();

  const lmsHttpClient: IHttpClient = new AxiosHttpClient({
    baseURL: AppSettings.lmsApiEndpoint,
    defaultHeaders: {
      tenantID,
      Authorization: idmToken ?? undefined,
    },
  });

  const rsaApiHttpClient: IHttpClient = new AxiosHttpClient({
    baseURL: AppSettings.rsaApiEndpoint,
    defaultHeaders: {
      tenantID,
      Authorization: idmToken ?? undefined,
    },
  });

  const orgModule = new OrgAPI({
    endpoint: orgModEndpoint,
    authorizationToken: idmToken ?? undefined,
    tenantID,
  });

  const value = useMemo<IServicesContextValue>(
    () => ({
      tenantsService: new TenantsService(lmsHttpClient),
      systemSettingsService: new SystemSettingsService(rsaApiHttpClient),
      userManagementService: new UserManagementService(rsaApiHttpClient),
      categoriesService: new CategoriesService(rsaApiHttpClient),
      activitiesService: new ActivitiesService(rsaApiHttpClient),
      submissionsService: new SubmissionsService(rsaApiHttpClient),
      dashboardService: new DashboardService(rsaApiHttpClient),
      orgModule,
    }),
    [idmToken],
  );

  return (
    <ServicesContext.Provider value={value}>
      {children}
    </ServicesContext.Provider>
  );
}
