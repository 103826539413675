import { Navigate, RouteObject } from 'react-router-dom';

import { RoleGuard } from '@/router/guards/RoleGuard';
import { routes } from '@app/config/constants/routes';
import { lazyLoad } from '@app/utils/lazyLoad';

const { PendingSubmissions } = lazyLoad(
  () => import('@ui/pages/PendingSubmissions'),
);
const { Dashboard } = lazyLoad(() => import('@ui/pages/Dashboard'));
const { Settings } = lazyLoad(() => import('@ui/pages/Settings'));
const { Categories } = lazyLoad(() => import('@ui/pages/Categories'));
const { SystemSettings } = lazyLoad(() => import('@ui/pages/SystemSettings'));
const { UserManagement } = lazyLoad(() => import('@ui/pages/UserManagement'));
const { UserManagementEdit } = lazyLoad(
  () => import('@ui/pages/UserManagementEdit'),
);

export const adminRoutes: RouteObject[] = [
  {
    element: <RoleGuard allowedRoles="ADMIN" />,
    children: [
      {
        path: '/',
        element: <Navigate to={routes.pendingSubmissions} replace />,
      },
      { path: routes.pendingSubmissions, element: <PendingSubmissions /> },
      { path: routes.settings, element: <Settings /> },
      { path: routes.settingsCategories, element: <Categories /> },
      { path: routes.dashboard, element: <Dashboard /> },
      { path: routes.settingsSystem, element: <SystemSettings /> },
      { path: routes.settingsUsers, element: <UserManagement /> },
      { path: routes.settingsEditUsers, element: <UserManagementEdit /> },
    ],
  },
];
