import axios, { AxiosError, type AxiosInstance } from 'axios';

import { IHttpClient } from '../@interfaces/IHttpClient';

interface IAxiosHttpClientOptions {
  baseURL: string;
  defaultHeaders?: Record<string, string | undefined>;
}

export class AxiosHttpClient implements IHttpClient {
  private readonly client: AxiosInstance;

  constructor({ baseURL, defaultHeaders }: IAxiosHttpClientOptions) {
    this.client = axios.create({
      baseURL,
      headers: defaultHeaders,
    });
  }

  async get<T = unknown>(path: string): Promise<IHttpClient.Response<T>> {
    try {
      const { data, status } = await this.client.get<T>(path);

      return {
        statusCode: status ?? 200,
        body: data,
      };
    } catch (error) {
      return this.errorHandler(error as Error);
    }
  }

  async post<T>(
    path: string,
    { body, ...params }: IHttpClient.HttpParams = {},
  ): Promise<IHttpClient.Response<T>> {
    try {
      const { data, status } = await this.client.post<T>(path, body, params);

      return {
        statusCode: status ?? 201,
        body: data,
      };
    } catch (error) {
      return this.errorHandler(error as Error);
    }
  }

  async put<T>(
    path: string,
    { body, ...params }: IHttpClient.HttpParams = {},
  ): Promise<IHttpClient.Response<T>> {
    try {
      const { data, status } = await this.client.put<T>(path, body, params);

      return {
        statusCode: status ?? 200,
        body: data,
      };
    } catch (error) {
      return this.errorHandler(error as Error);
    }
  }

  async delete<T>(path: string): Promise<IHttpClient.Response<T>> {
    try {
      const { data, status } = await this.client.delete<T>(path);

      return {
        statusCode: status ?? 200,
        body: data,
      };
    } catch (error) {
      return this.errorHandler(error as Error);
    }
  }

  private errorHandler(error: Error) {
    if (error instanceof AxiosError) {
      return {
        statusCode: error.response?.status ?? 400,
        body: error.response?.data,
      };
    }

    // eslint-disable-next-line no-console
    console.error(error);

    return {
      statusCode: 400,
      body: {
        message: 'Unknown error.',
      },
    };
  }
}
