import { IUser } from '@app/entities/IUser';

export class UserMapper {
  static toDomain(data: any): IUser {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      id: data.id,
      displayname: data.displayname,
      email: data.email,
      phoneNumber: data.phoneNumber,
      businessname: data.businessname,
      gstnumber: data.gstnumber,
      timepaidshortdistance: data.timepaidshortdistance,
      annualrsamaxexpenses: data.annualrsamaxexpenses
        ? Number(data.annualrsamaxexpenses)
        : 0,
      hourlyrate: data.hourlyrate ? Number(data.hourlyrate) : 0,
    };
  }
}
