import { ISystemSettings } from '@app/entities/ISystemSettings';

import { IHttpClient } from '../@interfaces/IHttpClient';
import { SystemSettingsMapper } from '../mappers/SystemSettingsMapper';

export class SystemSettingsService {
  constructor(private readonly httpClient: IHttpClient) {}

  async getSettings() {
    const { body, statusCode } = await this.httpClient.get('/settings');

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    const settings = SystemSettingsMapper.toDomain(body);

    return {
      statusCode,
      body: settings,
    };
  }

  save(settings: ISystemSettings) {
    return this.httpClient.put('/settings', {
      body: settings,
    });
  }
}
