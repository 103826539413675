import './ui/assets/styles/index.css';
import './app/lib/streamSaver';
// Log rocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//
import ReactDOM from 'react-dom/client';

import App from './App';
//
if (process.env.LOGROCKET_APP_ID) {
  LogRocket.init(process.env.LOGROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
);
