import { ISubmission } from '@app/entities/ISubmission';

export class SubmissionsMapper {
  static toDomain(data: any): ISubmission {
    return {
      id: data.id,
      userID: data.userID,
      status: data.status,
      cor: data.cor,
      consulting: {
        consultinggst: data.consultinggst,
        consultingaftertax: data.consultingaftertax,
        consultinghours: data.consultinghours,
      },
      meal: data.meal,
      travel: {
        kmgst: data.kmgst,
        kmreimbursement: data.kmreimbursement,
        km: data.km,
        kmtotal: data.kmtotal,
        travelreimbursement: data.travelreimbursement,
        traveltime: data.traveltime,
        traveltimegst: data.traveltimegst,
      },
      othercosts: data.othercosts,
      totalclaim: data.totalclaim,
      totalclaimaftertax: data.totalclaimaftertax,
      totalclaimgst: data.totalclaimgst,
      submissiontimestamp: data.submissiontimestamp,
      invoicenumber: data.invoicenumber,
    };
  }
}
