import { createContext, useLayoutEffect, useMemo } from 'react';

import { useTenant } from '@app/hooks/contexts/useTenant';
import { makeMappedTheme } from '@app/utils/makeMappedTheme';
import { themes } from '@ui/themes';
import { Theme } from '@ui/themes/@interfaces/Theme';

type IThemeContextValue = Theme;

export const ThemeContext = createContext({} as IThemeContextValue);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { tenantID } = useTenant();

  const theme = useMemo(() => {
    const baseTheme = themes.base;
    const tenantTheme = themes[tenantID as keyof typeof themes] ?? {};

    return {
      ...baseTheme,
      ...tenantTheme,
    };
  }, [tenantID]);

  useLayoutEffect(() => {
    const mappedTheme = makeMappedTheme(theme);

    Object.entries(mappedTheme).forEach(([variable, value]) => {
      document.documentElement.style.setProperty(variable, value);
    });
  }, [theme]);

  const value = useMemo<IThemeContextValue>(() => ({ ...theme }), [theme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}
