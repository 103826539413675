import { IHttpClient } from '../@interfaces/IHttpClient';
import { CategoryMapper } from '../mappers/CategoryMapper';

export class CategoriesService {
  constructor(private readonly httpClient: IHttpClient) {}

  create({ description }: { description: string }) {
    return this.httpClient.post('/category/new', {
      body: {
        description,
      },
    });
  }

  update(id: string, { description }: { description: string }) {
    return this.httpClient.put(`/category/${id}`, {
      body: {
        description,
      },
    });
  }

  async delete(id: string) {
    return this.httpClient.delete(`/category/${id}`);
  }

  async list() {
    const { body, statusCode } = await this.httpClient.get('/categories');

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    const categories = body.categories.map(CategoryMapper.toDomain);

    return {
      statusCode,
      body: categories,
    };
  }
}
