import { ClassificationUnitObject } from '@ikonintegration/mod-organization-client';
import { createContext, useMemo, useState } from 'react';

import { ITenantConfig } from '@app/entities/ITenantConfig';
import { IUser } from '@app/entities/IUser';
import { useAuth } from '@app/hooks/contexts/useAuth';
import { FullScreenLoader } from '@ui/components/ui/FullScreenLoader';

import { useLoadInitialData } from './useLoadInitialData';

interface IGlobalCacheContextValue {
  tenantConfig: ITenantConfig | undefined;
  users: { users: any } | undefined;
  user: IUser;
  cus: ClassificationUnitObject[];
}

export const GlobalCacheContext = createContext({} as IGlobalCacheContextValue);

export function GlobalCacheProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isReady, setIsReady] = useState(false);
  const { signedIn, logout } = useAuth();
  const { tenantConfig, users, user, cus } = useLoadInitialData({
    enabled: signedIn,
    onSuccess: () => setIsReady(true),
    onError: logout,
  });

  const value = useMemo(
    () => ({ tenantConfig, users, user, cus }),
    [tenantConfig, users, user, cus],
  );

  if (!isReady) {
    return <FullScreenLoader />;
  }

  return (
    <GlobalCacheContext.Provider value={value}>
      {children}
    </GlobalCacheContext.Provider>
  );
}
