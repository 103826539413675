import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useServices } from '@app/hooks/contexts/useServices';

interface IUseTenantConfigOptions {
  enabled?: boolean;
}

export function useTenantConfig(options?: IUseTenantConfigOptions) {
  const { enabled } = options ?? {};
  const { tenantsService } = useServices();

  const { data, isSuccess, isError } = useQuery({
    staleTime: Infinity,
    queryKey: ['tenantconfig'],
    queryFn: async () => {
      const resp = await tenantsService.getConfiguration();

      if (resp.statusCode === 200) {
        return resp.body;
      }

      throw new Error('Error while loading Tenant config.');
    },
    enabled,
  });

  return useMemo(
    () => ({
      data,
      isSuccess,
      isError,
    }),
    [data, isSuccess, isError],
  );
}
