import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from '@app/lib/queryClient';

import { AuthProvider } from './AuthContext';
import { GlobalCacheProvider } from './GlobalCacheContext';
import { ServicesProvider } from './ServicesContext';
import { TenantProvider } from './TenantContext';
import { ThemeProvider } from './ThemeContext';

export function RootProvider({ children }: { children: React.ReactNode }) {
  return (
    <TenantProvider>
      <ThemeProvider>
        <AuthProvider>
          <ServicesProvider>
            <QueryClientProvider client={queryClient}>
              <GlobalCacheProvider>{children}</GlobalCacheProvider>
              <ReactQueryDevtools position="bottom-right" />
            </QueryClientProvider>
          </ServicesProvider>
        </AuthProvider>
      </ThemeProvider>
    </TenantProvider>
  );
}
