import { isIOS } from 'react-device-detect';

export function openExternalURL(url: string, appendProtocol = false) {
  let preparedURL = url;

  if (appendProtocol) {
    const curr = new URL(window.location.href);
    preparedURL = `${curr.protocol.replace(':', '')}://${preparedURL}`;
  }

  if (isIOS) {
    window.location.href = preparedURL;
    return;
  }

  window.open(preparedURL, '_blank');
  window.focus();
}
