import { IHttpClient } from '../@interfaces/IHttpClient';
import { SubmissionsMapper } from '../mappers/SubmissionsMapper';

export class SubmissionsService {
  constructor(private readonly httpClient: IHttpClient) {}

  async list() {
    const { body, statusCode } = await this.httpClient.get(
      '/submissions/status/submitted',
    );

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    const submissions = body.submissions.map(SubmissionsMapper.toDomain);

    return {
      statusCode,
      body: submissions,
    };
  }

  getSubmissionsHistory(fromDate: string, toDate: string, userID?: string) {
    return this.httpClient.post('/submissions/period', {
      body: {
        filter: {
          fromDate,
          toDate,
          userID,
        },
      },
    });
  }

  add(
    userID: string,
    yearmonth: string,
    lgentries: string[],
    invoicenumber?: string,
  ) {
    return this.httpClient.post(`/submission/${userID}/${yearmonth}`, {
      body: {
        lgentries,
        invoicenumber,
      },
    });
  }

  approve(userID: string, yearmonth: string) {
    return this.httpClient.put(`/submission/${userID}/${yearmonth}/approve`, {
      body: {
        approved: true,
      },
    });
  }

  reject(userID: string, yearmonth: string, rejectionReason: string) {
    return this.httpClient.put(`/submission/${userID}/${yearmonth}/approve`, {
      body: {
        approved: false,
        rejectionReason,
      },
    });
  }
}
