export const routes = {
  activityReport: '/activity-report',
  newActivityReport: '/activity-report/new',
  newActivityReportDuplicate: '/activity-report/:activityId/new',
  editActivityReport: '/activity-report/:userID/:activityId/edit',
  activityByRSA: '/activity-by-rsa',
  dashboard: '/dashboard',
  pendingSubmissions: '/pending-submissions',
  submissionHistory: '/submimission-history',
  reports: '/reports',
  settings: '/settings',
  settingsCategories: '/settings/categories',
  settingsSystem: '/settings/system',
  settingsUsers: '/settings/users',
  settingsEditUsers: '/settings/users/:userId/edit',
  viewSubmissions: '/activities/:userID/:dateFrom/:dateTo',
};

export type RouteKey = keyof typeof routes;
